<template>
  <div
    id="contents"
    :class="
      tabIndex == 1 ? 'raw_materials_order_regist' : 'raw_materials_order'
    "
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <button class="btn_admin" :disabled="!managementMode">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li class="active">
            <a>발주현황조회</a>
          </li>
        </ul>
      </div>
      <custom-order-status-form></custom-order-status-form>
    </div>
  </div>
</template>

<script>
import CustomOrderStatusForm from '@/views/forms/Plan/10/CustomOrderStatusForm';

import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import SpinnerModal from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, SpinnerModal, ModalMixin, FavoriteMixin],
  components: {
    CustomOrderStatusForm,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromPurchasePage',
      tabIndex: 'getOpenTabIndexFromPurchasePage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToPurchasePage',
      SetOpenTabIndex: 'setOpenTabIndexToPurchasePage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPurchasePage');
      this.$route.meta.menu_init = false;
    }
    if (this.$route.meta.tab != undefined) {
      this.$store.commit('setOpenTabIndexToPurchasePage', this.$route.meta.tab);
      delete this.$route.meta.tab;
    }
  },
};
</script>

<style></style>
